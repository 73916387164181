import React, { useState } from 'react';
import './Cookie.scss';


function CookieConsentBanner() {
    const [cookieBanner, setCookieBanner] = useState(true);

    const handleAccept = () => {
        setCookieBanner(false);
        localStorage.setItem('Cookies', 'accept');
    };

    const handleDecline = () => {
        setCookieBanner(false);
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('Cookies')) {
                localStorage.removeItem(key);
            }
        });
    };

    if (cookieBanner === false) {
        return null;
    }

  return (
      <div>
        <div className='cookie-overlay'></div>
        <div className='cookie-banner'>
          <p>Ce site utilise des cookies. En continuant, vous acceptez l'utilisation des cookies.</p>
          <div className='cookie-buttons'>
            <button onClick={handleAccept} className='accept-cookie-button'>Accepter</button>
            <button onClick={handleDecline} className='reject-cookie-button'>Refuser</button>
          </div>
        </div>
      </div>
  );
};

export default CookieConsentBanner;