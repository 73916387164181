import React from 'react';
import './Home.scss';
import dataSkills from '../../data/data-skills.json';
import Banner from '../../components/Banner/Banner';
import image from '../../assets/banner-laptop.webp';
import About from '../../components/About/About';
import Collapse from '../../components/Collapse/Collapse';
import Cookie from '../../components/Cookie/Cookie';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Creating Home page with the function declaration that creates the Home functional component
function Home() {
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return ( 
		<HelmetProvider>
			<Helmet>
				<title>CAM/S CODE PROJECTS: Camille Jacquet - Développeuse web freelance</title>
      			<meta name="description" content="Camille Jacquet, développeuse web freelance, réalise vos projets web: création de site web, optimisation SEO, modification de votre site web existant, gestion de projet de développement." />
			</Helmet>
        	<main>
            	<section className='banner-home-section'>
                	{/* Use of the Banner component */}
					<Banner 
						title={'Camille Jacquet: Développeuse web'}
						image={image}
                    	alt={'Photo ordinateur portable'}
                    	description={'Ordinateur portable'}
					/>
            	</section>
				<section>
				<Cookie />
				</section>
            	<section className='about-section'>
          			<h2 className='about-section-title'>À propos</h2>
          			<hr></hr>
                	<About />
            	</section>
            	<section className='skills-section'>
                	<h2 className='title-skills-section'>Compétences</h2>
                	<hr></hr>
                	<div className= 'collapse-in-home-page'>
                		{/* Use of the Collapse component */}
						{/* The . map() method is used to iterate on each element of the dataSkills array. For each dataSkills, the Collapse component is rendered */}
						{dataSkills && dataSkills.length > 0 && dataSkills.map((data, index) =>
							<div className='button-collapse-in-home-page' key={index}>
								<Collapse
									title={data.title}
									content={data.content && data.content.length > 0 && data.content.map((content, index) => (
                                		<div className='details-skills' key={index}>{content}</div>
                            		))}
									open={'true'}	
								/>
							</div>
						)}
                	</div>
            	</section>
        	</main>
		</HelmetProvider>
	);
};

export default Home;

