import React from 'react';
import { Link } from 'react-router-dom';
import './404.scss';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Creating 404 page with the function declaration that creates the Error functional component
function Error() {
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// This code changes the URL displayed in the browser address bar to appear as if the user had accessed the '/404' URL
	useEffect(() => {
		window.history.replaceState(null, '404', '/404');
	  }, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title>CAM/S CODE PROJECTS: Camille Jacquet - Développeuse web freelance - Error</title>
      			<meta name="description" content="CAM/S CODE PROJECTS: Camille Jacquet, développeuse web freelance, page d'erreur. " />
			</Helmet>
			<main>
				<section className='error-section'>
					<h1 className='title-error'>404</h1>
					<p className='p-error'><span>Oups! La page que</span> <span>vous demandez n'existe pas.</span></p>
					<Link className='link-error' to="/">Retourner sur la page d'accueil</Link>
				</section>
			</main>
		</HelmetProvider>
	);
};

export default Error;