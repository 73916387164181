import React from 'react';
import './Header.scss';
import logo from '../../assets/logo-cams-code-projects.webp';
import { Link, useLocation } from 'react-router-dom';

// Header component declaration with a function
function Header() {

	// Uses the useLocation hook provided by React Router to get the `location` object that contains information about the current URL
	const location = useLocation();
	return (
		<header>
			<div className='header-style'>
				<Link to="/"><img src={logo} alt="Logo CAM/S CODE PROJECTS" title="Logo" width={300} height={60}/></Link>
				<nav>
					<ul className='navbar-list'>
						<li className='navbar-list-item'>
							<Link to="/" className={location.pathname === '/' ? 'navbar-link-active navbar-link' : ' navbar-link'}>
								Accueil
							</Link>
						</li>
						<li className='navbar-list-item'>
							<Link to="/portfolio" className={location.pathname === '/portfolio' ? 'navbar-link-active navbar-link' : ' navbar-link'}>
								Portfolio
							</Link>
						</li>
                        <li className='navbar-list-item'>
							<Link to="/contact" className={location.pathname === '/contact' ? 'navbar-link-active navbar-link' : ' navbar-link'}>
								Contact
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default Header;

