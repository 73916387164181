import React from 'react';
import './About.scss';
import image from '../../assets/portrait-camille-jacquet.webp';
import logo from '../../assets/logo-cv.webp';
import { Link } from 'react-router-dom';

// About component declaration with a function 
function About() {
  
    const pdfPath = '/pdf/cv-camille-jacquet.pdf';
  
    return (
        <div className='about-portrait-and-content'>
            <div className='portrait-and-logo-cv'>
                <img className='about-portrait' src={image} alt='Portrait de Camille Jacquet' title='Camille Jacquet' width={245} height={325}/>
                <a className='logo-cv' href={pdfPath} target="_blank" rel="noopener noreferrer" title='Voir mon CV'><img src={logo} alt='Logo CV pdf' title='Voir mon CV' width={70} height={70}/></a>
            </div>
            <div className='about-button-and-content'>
                <div className='about-content'>
                    <p>Je m'appelle <span>Camille Jacquet</span> et je suis <span>développeuse web freelance.</span></p>
                    <p>Je suis spécialisée dans <span>la création de sites web sur mesure, le référencement SEO
                    et la modernisation de sites existants.</span> Mon objectif est de fournir 
                    des solutions web personnalisées qui répondent aux besoins de mes clients, tout en 
                    optimisant leur visibilité en ligne grâce à des techniques avancées de référencement.</p>
                    <p>Le monde du web m'a toujours passionné, et c'est après avoir travaillé plusieurs années
                    dans le milieu de l'informatique que j'ai décidé de me reconvertir professionnellement, en suivant <a className='about-link-training-and-contact' href='https://openclassrooms.com/fr/paths/717-developpeur-web' target='_blank' rel='noopener noreferrer' title='Voir la formation'>une formation diplômante chez Openclassrooms,</a> pour ainsi devenir <span>développeuse web.</span></p>
                    <p>Au cours de cette formation, j'ai pu acquérir différentes compétences techniques en matière de développement front-end et back-end, grâce à la réalisation de plusieurs projets tirés de cas concrets d’entreprise.</p>
                    <p>L' ensemble des projets que j'ai réalisés est présenté dans <Link to='/portfolio' className='about-link-training-and-contact' title='Voir le portfolio'>ma page portfolio.</Link></p>
                    <p>En tant que développeuse web, j'ai hâte de relever de nouveaux défis en vous offrant une expérience web innovante qui répond à vos besoins.</p>
                    <p><span>Quel que soit votre domaine d'activité, posséder un site web de haute qualité et à votre image, constitue une stratégie efficace pour vous faire connaître au démarrage de votre activité, ou pour vous distinguer de vos concurrents dans votre région.</span></p>
                    <p>N'hésitez pas à <Link to='/contact' className='about-link-training-and-contact' title='Accéder au formulaire de contact'>me contacter</Link> pour échanger sur votre projet et obtenir <Link to='/contact' className='about-link-training-and-contact' title='Accéder au formulaire de contact pour demander un devis'>un devis gratuit.</Link></p>
                </div>
                <Link to='/portfolio' className='button-link-in-about-section' title='Voir les projets'>Voir le portfolio</Link>
            </div>
        </div>
    );
};

export default About;