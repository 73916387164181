import React from 'react';
import './Banner.scss';

// Banner component declaration with a function 
function Banner(props) {
    return (
        <div className='banner-style'>
            <img className='img-banner' src={props.image} alt={props.alt} title={props.description} width={1620} height={220} />
            {props.title && <h1 className='title-banner'>{props.title}</h1>}
        </div>
    );
};

export default Banner;
