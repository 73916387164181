import React from 'react';
import './Portfolio.scss';
import Banner from '../../components/Banner/Banner';
import image from '../../assets/banner-code.webp';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import dataProject from '../../data/data-projects.json';
import ProjectCard from '../../components/ProjectCard/ProjectCard';

// Creating Portfolio page with the function declaration that creates the Portfolio functional component
function Portfolio() {
    
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
        <HelmetProvider>
			<Helmet>
				<title>CAM/S CODE PROJECTS: Camille Jacquet - Développeuse web freelance - Portfolio</title>
      			<meta name="description" content="CAM/S CODE PROJECTS: Camille Jacquet, développeuse web freelance, vous présente son portfolio. " />
			</Helmet>
		    <main>
                <section className='banner-portfolio-section'>
                    {/* Use of the Banner component */}
                    <Banner
                        title={'Portfolio: Mes projets'}
                        image={image}
                        alt={'Photo code'}
                        description={'Code programmation'}
                    />
                </section>
                <section className='portfolio-section'>
                	<div className='projects'>
						{/* Use of the ProjectCard component */}
						{/* The . map() method is used to iterate on each element of the dataProject array. For each dataProject, the ProjectCard component is rendered */}
						{dataProject && dataProject.length > 0 && dataProject.map((data) =>
							<ProjectCard
								key={data.id}
								id={data.id}
								title={data.title}
                            	mission={data.mission}
								cover={data.cover}
							/>
						)}
					</div>
                </section>
            </main>
        </HelmetProvider>
    );
};

export default Portfolio;

