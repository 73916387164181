import React from 'react';
import './Form.scss';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// Form component declaration with a function 
function Form() {
    
	const [lastName, setLastName] = useState('');
	const [firstName, setFirstName] = useState('');
  	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
  	const [selection, setSelection] = useState('');
  	const [message, setMessage] = useState('');
	const [submitted, setSubmitted] = useState(false);

	const recaptchaRef = React.createRef();

	const handleSubmit = (e) => {
		e.preventDefault();
		  
		const recaptchaValue = recaptchaRef.current.getValue();
    		if (!recaptchaValue) {
      			alert('Veuillez résoudre le reCAPTCHA.');
      			return;
    		}

			if (!/^\d+$/.test(phoneNumber)) {
				alert('Le numéro de téléphone doit contenir uniquement des chiffres.');
				return;
		  	}
		    
            const formData = {
			    firstName,
				lastName,
			    phoneNumber,
			    email,
			    selection,
			    message
		    };

            fetch('https://formspree.io/f/xoqoqjpn', {
			    method: 'POST',
			    headers: {
			        'Content-Type': 'application/json'
			    },
			    body: JSON.stringify(formData)
		    })
			.then((response) => {
			    if (response.ok) {
				    setFirstName('');
					setLastName('');
				    setPhoneNumber('');
				    setEmail('');
				    setSelection('');
				    setMessage('');
				    setSubmitted(true);
			    }   else { 
				        console.log('Erreur lors de l\'envoi du formulaire');
			        }
			})
			.catch((error) => {
			    console.log('Une erreur s\'est produite :', error);
			});
	};
		
    useEffect(() => {
		if (submitted) {
			const timer = setTimeout(() => {
				setSubmitted(false);
			}, 4000); 
		
			return () => {
				clearTimeout(timer);
			};
		}
		}, [submitted]);
				
    return(
        <form onSubmit={handleSubmit} className='contact-formulary' method="post" action="#">
    		<label htmlFor="firstName">Prénom</label>
        	<input className='input-device' 
                name="firstName" 
                type="text" 
                id="firstName" 
                value={firstName} 
                onChange={(e) => setFirstName(e.target.value)} 
                required
            />
			
			<label htmlFor="lastName">Nom</label>
    		<input className='input-device' 
                name="lastName" 
                type="text" 
                id="lastName" 
                value={lastName} 
                onChange={(e) => setLastName(e.target.value)} 
                required 
            />
			
            <label htmlFor="phoneNumber">Téléphone</label>
        	<input className='input-device' 
                name="phoneNumber" 
                type="tel" 
                id="phoneNumber" 
                value={phoneNumber} 
                onChange={(e) => setPhoneNumber(e.target.value)} 
                required
            />
			
            <label htmlFor="email">Email</label>
    		<input className='input-device' 
                name="email" 
                type="email" 
                id="email" 
                autoComplete="email-adress" 
                value={email}
          		onChange={(e) => setEmail(e.target.value)} 
                required 
            />
						
            <label htmlFor="selection">Motif de contact</label>
        	<div className='select-style'>
                <select name="selection" id="selection" value={selection} onChange={(e) => setSelection(e.target.value)} required>
				    <option value="">Veuillez sélectionner un motif</option>
				    <option value="Je souhaite échanger sur un futur projet">Je souhaite échanger sur un futur projet</option>
          		    <option value="Je veux un site web">Je veux un site web</option>
				    <option value="Je veux que mon site web soit optimisé">Je veux que mon site web soit optimisé</option>
          		    <option value="Je veux modifier mon site web">Je veux modifier mon site web</option>
          		    <option value="Autre">Autre</option>
        	    </select>
            </div>
    		
            <label htmlFor="message">Message</label>
    		<textarea 
                name="message" 
                id="message" 
                cols="30" rows="10" 
                value={message}
          		onChange={(e) => setMessage(e.target.value)} 
                required>
            </textarea>
			
			<div className='recaptcha-container'>
				<div className='recaptcha'>
				{!submitted && <ReCAPTCHA sitekey="6LcgczAnAAAAAPmpORbsbjjWEB9hv3xTE7bLfuxM" ref={recaptchaRef} />}
				</div>
			</div>

            <input className='input-submit' type="submit" id="submit" value="Envoyer" />
			{submitted && (
        		<div className="success-message">Formulaire soumis avec succès!</div>
      		)}
		</form>
    );
};

export default Form;