import React from 'react';
import './Project.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Gallery from '../../components/Gallery/Gallery';
import Collapse from '../../components/Collapse/Collapse';
import dataProject from '../../data/data-projects.json';

// Creating Project page with the function declaration that creates the Project functional component
function Project() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	const idDataProject = useParams('id').id;

  	const navigate = useNavigate();

  	const [project, setProject] = useState(null);

	const [link, setLink] = useState(null);

	useEffect(() => {
		
		const data = dataProject.find((data) => data.id === idDataProject) || null;
		
		if (data === null) {
			return navigate('/404');
		} else {
		  	setProject(data);
		  
		  	if (data.link && data.id === idDataProject) {
				setLink(data.link);
		  	}
		}
	}, [idDataProject, navigate]);

	  if (!project) {
		return null;
	  }
	  
	return (
		<HelmetProvider>
			<Helmet>
				<title>CAM/S CODE PROJECTS: Camille Jacquet - Développeuse web freelance - Projet web détaillé</title>
      			<meta name="description" content="CAM/S CODE PROJECTS: Camille Jacquet, développeuse web freelance, vous présente les projets web de son portfolio en détail. " />
			</Helmet>
			<main>
				<section className='details-project-section'>
					{/* Use of the Gallery component */}
					<Gallery 
						pictures={project.pictures}
						title={project.mission}
						alt={project.title}
					/>
					<h1>{project.title}</h1>
					<hr></hr>
					<h2>Projet réalisé sur VSC</h2>
					<div className='tags-and-button-github'>
            			<div className='tags-details-project'>
							{project.tags && project.tags.length > 0 && project.tags.map((tags, index) =>
								<button className='button-tag' key={index}>{tags}</button>
							)}
						</div>
						{link && (
							<a className='github-button' href={link} target='_blank' rel='noopener noreferrer' title='Voir le code sur Github'>Code sur Github</a>
						)}
					</div>
					<div className='collapse-in-project-page'>
						{/* Use of the Collapse component */}
						<div className='button-collapse-in-project-page'>
							<Collapse
								title={'Contexte'}
								content={project.contexte}	
								open={'true'}		
                    		/>
						</div>
						<div className='button-collapse-in-project-page'>
							<Collapse
								title={'Réalisation'}
								content={project.réalisation}	
								open={'true'}		
                    		/>
						</div>
					</div>		
				</section>
			</main>
		</HelmetProvider>
	);
};

export default Project;

