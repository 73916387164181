import React, { useState } from 'react';
import './Collapse.scss';
import arrowopen from '../../assets/arrow.webp';

// Collapse component declaration with a function 
function Collapse(props) {
	
	// Definition of the local state of `is open` using the useState hook
	const [isOpen, setIsOpen] = useState(props.open !== undefined ? props.open: false);

	// Creation of an event management function that will update the value of isOpen when the user clicks on one of the collapses
	const handleClick = () => {
		setIsOpen(!isOpen);
	};
   
	return (
		<div className='collapse'>
			<h3 className='title-in-collapse' onClick={handleClick}>
				{props.title}
				<img className={isOpen ? 'arrow arrow-to-close': 'arrow arrow-to-open'}
					src={arrowopen}
					alt='show content'
					title='arrow'
                    width={24}
                    height={13}
				/>
			</h3>
			{isOpen && <div className='collapse-content'>{props.content}</div>}
	  	</div>
  	);
};

export default Collapse;