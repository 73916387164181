import React from 'react';
import './Contact.scss';
import Banner from '../../components/Banner/Banner';
import image from '../../assets/banner-keyboard.webp';
import Form from '../../components/Form/Form';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Creating Contact page with the function declaration that creates the Contact functional component
function Contact() {
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title>CAM/S CODE PROJECTS: Camille Jacquet - Développeuse web freelance - Formulaire de contact</title>
      			<meta name="description" content="CAM/S CODE PROJECTS: Prenez contact avec Camille Jacquet, développeuse web freelance, pour échanger sur vos projets web." />
			</Helmet>
			<main>
				<section className='banner-contact-section'>
                	{/* Use of the Banner component */}
					<Banner
						title={'Contactez-moi pour discuter de votre projet!'}
						image={image}
                    	alt={'Photo clavier ordinateur'}
                    	description={'Clavier ordinateur'}
					/>
            	</section>
				<section className='contact-section'>
					<h2 className='contact-title'>Contact</h2>
					<hr></hr>
					{/* Use of the Form component */}
					<Form />
				</section>
			</main>
		</HelmetProvider>
	);
};

export default Contact;

