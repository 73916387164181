import React from 'react';
import './ProjectCard.scss';
import { Link } from 'react-router-dom';

// ProjectCard component declaration with a function 
function ProjectCard(props) {
    return (
        <article className='card-project'>
            <Link to={`/portfolio/project/${props.id}`}>
				<div className='filter-project-card'></div>
                <img src={props.cover} alt={props.mission} title={props.title} width={490} height={490}/>
                <div className='title-and-mission'>
                    <h2 className='card-title'>{props.title}</h2>
                    <p className='see-more'>Cliquer pour voir plus</p>
                    <h3 className='mission'>{props.mission}</h3>
                </div>
            </Link>
        </article> 
    );
};

export default ProjectCard;

