import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import Project from './pages/Project/Project';
import Contact from './pages/Contact/Contact';
import Error from './pages/404/404';
import Header from "./components/Header/Header";
import Footer from './components/Footer/Footer';

// Configuring application routing with the function declaration that creates the functional component App
function App() {
    return ( 
    <BrowserRouter>
            {/* Using the Header component in all views (pages) of the application */}
            <Header />
        <Routes>
            {/* Use of the Home component to display all elements of the Home page */}
            <Route path="/" element={<Home />} /> 
            
            {/* Use of the Portfolio component to display all elements of the Portfolio page */}
            <Route path="/portfolio" element={<Portfolio />} /> 
            
            {/* Use of the Project component to display all elements of the Project page */}
            <Route path="/portfolio/project/:id" element={<Project />} /> 

            {/* Use of the Contact component to display all elements of the Contact page */}
            <Route path="/contact" element={<Contact />} /> 
            
            {/* Use of the Error component to display all elements of the 404 page */}
            <Route path="*" element={<Error />} /> 

        </Routes>
            {/* Using the Footer component in all views (pages) of the application */}
            <Footer />
    </BrowserRouter> 
    );
};

export default App;