import React from 'react';
import './Footer.scss';
import logo from '../../assets/logo-cams-code-projects.webp'
import { Link } from 'react-router-dom';
import logoGitHub from '../../assets/github-logo.webp';
import logoLinkedin from '../../assets/linkedin-logo.webp';

// Footer component declaration with a function
function Footer() {
	return (
		<footer>
			<a href='https://github.com/camipro-cell' target='_blank' rel='noopener noreferrer' title='Voir le profil sur GitHub'><img src={logoGitHub} alt='Logo GitHub' title='GitHub' width={70} height={70} /></a>
			<div className='footer-informations'>
				<Link to={'/'}>
					<img className='logo-projects' src={logo} alt="CAM/S CODE PROJECTS logo" title="CAM/S CODE PROJECTS" width={300} height={60}/>
				</Link>
				<p className='author'>© 2023 - Camille Jacquet</p>
				<p className='contact-informations'>Du lundi au vendredi de 8h00 à 18h00</p> 
				<p className='contact-informations'>07 61 89 97 64</p> 
				<p className='contact-informations'>Région Centre-Val de Loire</p> 
			</div>	
				<a href='https://www.linkedin.com/in/jacquetcamille' target='_blank' rel='noopener noreferrer' title='Voir le profil sur Linkedin'><img className='logo-linkedin' src={logoLinkedin} alt='Logo Linkedin' title='Linkedin' width={70} height={70}/></a>
		</footer>
	);
};

export default Footer;